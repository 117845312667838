<template>
  <div>
    <div v-if="loadingTasksInProgress">Загрузка задания...</div>
    <template v-else-if="errorText == ''">
      <div v-for="task in tasks" :key="task.num">
        <div v-if="raports.length != 0">
          <h3>Ранее введенные рапортички:</h3>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td><strong>№</strong></td>
                <td><strong>Дата</strong></td>
                <td>
                  <strong>{{ task.name1_str }}</strong>
                </td>
                <td>
                  <strong>{{ task.name2_str }}</strong>
                </td>
                <td></td>
              </tr>
            </thead>

            <template v-for="raport in raports" :key="raport.raport_num">
              <tr
                :class="{
                  selected_raport_row: raport.raport_num == selected_raport_num,
                }"
              >
                <td>{{ raport.raport_num }}</td>
                <td>{{ dateToStringDDMMYYYY(raport.date) }}</td>
                <td>{{ raport.count1 }}</td>
                <td>{{ raport.count2 }}</td>
                <td>
                  <div class="task__btns">
                    <button
                      class="btn btn-success m2"
                      @click="startEditRaport(raport)"
                    >
                      Изменить
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </table>

          <br />
        </div>

        <h3>
          Рапортичка <strong>{{ selected_raport_num_str }}</strong> по заданию
          на ЛГК №
          {{ $route.params.num }}
        </h3>

        <table class="table table-bordered">
          <tr>
            <td colspan="4">Марка, профиль</td>
            <td align="left">{{ task.marka }}, пр. {{ task.profil }}</td>
            <td>Номер бригады</td>
            <td>
              <input
                v-model="result.brigade_num"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td colspan="2"></td>
          </tr>

          <tr>
            <td colspan="4">Сырье</td>
            <td rowspan="2">Общий расход сырья, кг</td>
            <td colspan="2">{{ task.zayavka_num1_str }}</td>
            <td colspan="2">{{ task.zayavka_num2_str }}</td>
          </tr>

          <tr>
            <td colspan="2">Наименование</td>
            <td colspan="2">Пл., формат</td>
            <td colspan="2">
              <strong>{{ task.name1_str }}</strong>
              <div v-if="task.tehkarta1" class="task__btns">
                <tehkarta-buttons :num="task.tehkarta1"></tehkarta-buttons>
              </div>
            </td>
            <td colspan="2">
              <strong>{{ task.name2_str }}</strong>
              <div v-if="task.tehkarta2" class="task__btns">
                <tehkarta-buttons :num="task.tehkarta2"></tehkarta-buttons>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2" align="left">{{ task.mat1_name }}</td>
            <td>{{ task.mat1_density }}</td>
            <td>{{ task.mat1_format }}</td>
            <td>
              <input
                v-model="result.mat1_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>{{ task.zag_dimens1_str }}</td>
            <td>{{ task.zag_sq1 }}</td>
            <td>{{ task.zag_dimens2_str }}</td>
            <td>{{ task.zag_sq2 }}</td>
          </tr>

          <tr>
            <td colspan="2" align="left">{{ task.mat2_name }}</td>
            <td>{{ task.mat2_density }}</td>
            <td>{{ task.mat2_format }}</td>
            <td>
              <input
                v-model="result.mat2_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td colspan="2" rowspan="7">{{ task.strip_count1_str }}</td>
            <td colspan="2" rowspan="7">{{ task.strip_count2_str }}</td>
          </tr>

          <tr>
            <td colspan="2" align="left">{{ task.mat3_name }}</td>
            <td>{{ task.mat3_density }}</td>
            <td>{{ task.mat3_format }}</td>
            <td>
              <input
                v-model="result.mat3_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2" align="left">
              <input
                v-model="result.mat4_name"
                type="text"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat4_density"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat4_format"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat4_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2" align="left">
              <input
                v-model="result.mat5_name"
                type="text"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat5_density"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat5_format"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat5_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2" align="left">
              <input
                v-model="result.mat6_name"
                type="text"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat6_density"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat6_format"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat6_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2" align="left">
              <input
                v-model="result.mat7_name"
                type="text"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat7_density"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat7_format"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat7_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2" align="left">
              <input
                v-model="result.mat8_name"
                type="text"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat8_density"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat8_format"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>
              <input
                v-model="result.mat8_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2">Двухслойка, кг:</td>
            <td colspan="2">
              <input
                v-model="result.two_layer_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td>м2</td>
            <td>шт</td>
            <td>
              <input
                v-model="result.count1"
                type="number"
                class="form-control"
                :disabled="!task.need1"
                :class="{ input_result: task.need1 }"
              />
            </td>
            <td>шт</td>
            <td>
              <input
                v-model="result.count2"
                type="number"
                :disabled="!task.need2"
                :class="{ input_result: task.need2 }"
                class="form-control"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2">Трехслойка рулонная, кг:</td>
            <td colspan="2">
              <input
                v-model="result.three_layer_kg"
                type="number"
                class="input_result form-control"
              />
            </td>
            <td rowspan="3">
              <strong>{{ countM2 }}</strong>
            </td>
            <td>м2</td>
            <td>{{ countM2_1 }}</td>
            <td>м2</td>
            <td>{{ countM2_2 }}</td>
          </tr>

          <tr>
            <td colspan="4" rowspan="2" style="font-size: large">
              <div class="form-check form-switch" style="margin-left: 30px">
                <label class="form-check-label" for="cbTaskComplete">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="task.complete"
                    id="cbTaskComplete"
                  />
                  Все задание выполнено
                </label>
              </div>
            </td>
            <td>н/к, шт</td>
            <td>
              <input
                v-model="result.count_nekond1"
                type="number"
                :disabled="!task.need1"
                :class="{ input_result: task.need1 }"
                class="form-control"
              />
            </td>
            <td>н/к, шт</td>
            <td>
              <input
                v-model="result.count_nekond2"
                type="number"
                :disabled="!task.need2"
                :class="{ input_result: task.need2 }"
                class="form-control"
              />
            </td>
          </tr>

          <tr>
            <td>Срывы, брак, шт</td>
            <td>
              <input
                v-model="result.count_brak1"
                type="number"
                :disabled="!task.need1"
                :class="{ input_result: task.need1 }"
                class="form-control"
              />
            </td>
            <td>Срывы, брак, шт</td>
            <td>
              <input
                v-model="result.count_brak2"
                type="number"
                :disabled="!task.need2"
                :class="{ input_result: task.need2 }"
                class="form-control"
              />
            </td>
          </tr>
        </table>

        <button
          class="btn btn-success m2"
          :disabled="
            saveButtonDisabled || result.count1 == 0 || result.brigade_num == 0
          "
          @click="
            saveRaport(task.num, task.complete, selected_raport_num, result)
          "
        >
          {{ btnSave_title }}
        </button>

        <button
          v-if="selected_raport_num != ''"
          class="btn btn-success m2"
          @click="clearRaport"
        >
          Создать новую рапортичку
        </button>

        <button class="btn btn-dark m2" type="button" @click="$router.go(-1)">
          Вернуться назад
        </button>
      </div>
    </template>
    <div v-if="errorText != ''">{{ errorText }}</div>
  </div>
</template>
   
<script>
import TehkartaButtons from "../components/TehkartaButtons.vue";
import { fetchTaskDocGline } from "../api.js";
import { saveTaskDocGline } from "../api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

export default {
  components: { TehkartaButtons },

  data() {
    return {
      tasks: [],
      raports: [],
      num: "",
      errorText: "",
      loadingTasksInProgress: false,
      selected_raport_num: "",
      saveButtonDisabled: false,
      result: {
        task_complete: false,
        brigade_num: "",
        mat1_kg: "",
        mat2_kg: "",
        mat3_kg: "",
        mat4_kg: "",
        mat5_kg: "",
        mat6_kg: "",
        mat7_kg: "",
        mat8_kg: "",
        mat4_name: "",
        mat5_name: "",
        mat6_name: "",
        mat7_name: "",
        mat8_name: "",
        mat4_density: "",
        mat5_density: "",
        mat6_density: "",
        mat7_density: "",
        mat8_density: "",
        mat4_format: "",
        mat5_format: "",
        mat6_format: "",
        mat7_format: "",
        mat8_format: "",
        count1: "",
        count_nekond1: "",
        count_brak1: "",
        count2: "",
        count_nekond2: "",
        count_brak2: "",
        two_layer_kg: "",
        three_layer_kg: "",
      },
    };
  },

  watch: {
    tasks(newTasks) {
      newTasks.forEach((task) => {
        task.need1 =
          task.zayavka_num1 != "" || task.without_zayavka1 ? true : false;
        task.need2 =
          task.zayavka_num2 != "" || task.without_zayavka2 ? true : false;

        task.zayavka_num1_str = task.without_zayavka1
          ? "Без заявки"
          : task.zayavka_num1 == ""
          ? ""
          : "Заявка №" + task.zayavka_num1;
        task.zayavka_num2_str = task.without_zayavka2
          ? "Без заявки"
          : task.zayavka_num2 == ""
          ? ""
          : "Заявка №" + task.zayavka_num2;

        task.todate1_str =
          task.zayavka_num1 == ""
            ? ""
            : this.dateToStringDDMMYYYY(task.todate1);
        task.todate2_str =
          task.zayavka_num2 == ""
            ? ""
            : this.dateToStringDDMMYYYY(task.todate2);

        task.name1_str =
          task.tehkarta1 == ""
            ? ""
            : "тк. №" + task.tehkarta1 + " - " + task.name1;
        task.name2_str =
          task.tehkarta2 == ""
            ? ""
            : "тк. №" + task.tehkarta2 + " - " + task.name2;

        task.zag_sq1 =
          task.zag_width1 == 0
            ? ""
            : "S = " +
              ((task.zag_width1 * task.zag_length1) / 1000000).toFixed(3);
        task.zag_sq2 =
          task.zag_width2 == 0
            ? ""
            : "S = " +
              ((task.zag_width2 * task.zag_length2) / 1000000).toFixed(3);

        task.zag_width1_str = task.zag_width1 == 0 ? "" : task.zag_width1;
        task.zag_width2_str = task.zag_width2 == 0 ? "" : task.zag_width2;

        task.zag_dimens1_str =
          task.zag_width1 == 0
            ? ""
            : task.zag_width1 + " x " + task.zag_length1;
        task.zag_dimens2_str =
          task.zag_width2 == 0
            ? ""
            : task.zag_width2 + " x " + task.zag_length2;

        task.zag_length1_str = task.zag_length1 == 0 ? "" : task.zag_length1;
        task.zag_length2_str = task.zag_length2 == 0 ? "" : task.zag_length2;

        task.strip_count1_str =
          task.strip_count1 == 0 ? "" : "Полос: " + task.strip_count1;
        task.strip_count2_str =
          task.strip_count2 == 0 ? "" : "Полос: " + task.strip_count2;

        task.count1_str = task.count1 == 0 ? "" : task.count1 + " заг";
        task.count2_str = task.count2 == 0 ? "" : task.count2 + " заг";

        task.count_m2_1_str =
          task.count_m2_1 == 0 ? "" : task.count_m2_1 + " м2";
        task.count_m2_2_str =
          task.count_m2_2 == 0 ? "" : task.count_m2_2 + " м2";
      });
    },
  },

  methods: {
    async loadTasks() {
      this.loadingTasksInProgress = true;
      const fetchResult = await fetchTaskDocGline(this.num);

      this.tasks = fetchResult.data.tasks;
      this.raports = fetchResult.data.raports;
      this.errorText = fetchResult.errorText;
      this.updateMat456fromTask();

      this.loadingTasksInProgress = false;
    },

    async saveRaport(taskNum, task_complete, selected_raport_num, data) {
      this.saveButtonDisabled = true;
      const fetchResult = await saveTaskDocGline(
        taskNum,
        task_complete,
        selected_raport_num,
        data
      );
      if (fetchResult.bool_result == true) {
        this.selected_raport_num = fetchResult.selected_raport_num;
        this.loadTasks().then((this.saveButtonDisabled = false));
      } else {
        alert("Ошибка: " + fetchResult.errorText);
        this.saveButtonDisabled = false;
      }
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },

    updateMat456fromTask() {
      if (this.tasks.length > 0) {
        this.result.mat4_name = this.tasks[0].mat4_name;
        this.result.mat5_name = this.tasks[0].mat5_name;
        this.result.mat6_name = this.tasks[0].mat6_name;
        this.result.mat7_name = this.tasks[0].mat7_name;
        this.result.mat8_name = this.tasks[0].mat8_name;
        this.result.mat4_density = this.tasks[0].mat4_density;
        this.result.mat5_density = this.tasks[0].mat5_density;
        this.result.mat6_density = this.tasks[0].mat6_density;
        this.result.mat7_density = this.tasks[0].mat7_density;
        this.result.mat8_density = this.tasks[0].mat8_density;
        this.result.mat4_format = this.tasks[0].mat4_format;
        this.result.mat5_format = this.tasks[0].mat5_format;
        this.result.mat6_format = this.tasks[0].mat6_format;
        this.result.mat7_format = this.tasks[0].mat7_format;
        this.result.mat8_format = this.tasks[0].mat8_format;
      } else {
        this.result.mat4_name = "";
        this.result.mat5_name = "";
        this.result.mat6_name = "";
        this.result.mat7_name = "";
        this.result.mat8_name = "";
        this.result.mat4_density = "";
        this.result.mat5_density = "";
        this.result.mat6_density = "";
        this.result.mat7_density = "";
        this.result.mat8_density = "";
        this.result.mat4_format = "";
        this.result.mat5_format = "";
        this.result.mat6_format = "";
        this.result.mat7_format = "";
        this.result.mat8_format = "";
      }
    },

    clearRaport() {
      this.selected_raport_num = "";
      this.result.mat1_kg = "";
      this.result.mat2_kg = "";
      this.result.mat3_kg = "";
      this.result.mat4_kg = "";
      this.result.mat5_kg = "";
      this.result.mat6_kg = "";
      this.result.mat7_kg = "";
      this.result.mat8_kg = "";

      this.updateMat456fromTask();

      this.result.count1 = "";
      this.result.count_nekond1 = "";
      this.result.count_brak1 = "";
      this.result.count2 = "";
      this.result.count_nekond2 = "";
      this.result.count_brak2 = "";
      this.result.two_layer_kg = "";
      this.result.three_layer_kg = "";
      this.result.brigade_num = "";
    },

    startEditRaport(raport) {
      this.selected_raport_num = raport.raport_num;
      this.result.brigade_num = raport.brigade_num;
      this.result.mat1_kg = raport.mat1_kg;
      this.result.mat2_kg = raport.mat2_kg;
      this.result.mat3_kg = raport.mat3_kg;
      this.result.mat4_kg = raport.mat4_kg;
      this.result.mat5_kg = raport.mat5_kg;
      this.result.mat6_kg = raport.mat6_kg;
      this.result.mat7_kg = raport.mat7_kg;
      this.result.mat8_kg = raport.mat8_kg;
      this.result.mat4_name = raport.mat4_name;
      this.result.mat5_name = raport.mat5_name;
      this.result.mat6_name = raport.mat6_name;
      this.result.mat7_name = raport.mat7_name;
      this.result.mat8_name = raport.mat8_name;
      this.result.mat4_density = raport.mat4_density;
      this.result.mat5_density = raport.mat5_density;
      this.result.mat6_density = raport.mat6_density;
      this.result.mat7_density = raport.mat7_density;
      this.result.mat8_density = raport.mat8_density;
      this.result.mat4_format = raport.mat4_format;
      this.result.mat5_format = raport.mat5_format;
      this.result.mat6_format = raport.mat6_format;
      this.result.mat7_format = raport.mat7_format;
      this.result.mat8_format = raport.mat8_format;
      this.result.count1 = raport.count1;
      this.result.count_nekond1 = raport.count_nekond1;
      this.result.count_brak1 = raport.count_brak1;
      this.result.count2 = raport.count2;
      this.result.count_nekond2 = raport.count_nekond2;
      this.result.count_brak2 = raport.count_brak2;
      this.result.two_layer_kg = raport.two_layer_kg;
      this.result.three_layer_kg = raport.three_layer_kg;
    },
  },

  computed: {
    btnSave_title() {
      return this.selected_raport_num == ""
        ? "Записать новую рапортичку"
        : "Перезаписать рапортичку № " + this.selected_raport_num;
    },

    selected_raport_num_str() {
      return this.selected_raport_num == ""
        ? "(новая рапортичка)"
        : "№ " + this.selected_raport_num;
    },

    countM2_1() {
      const sq_zag1 =
        Math.round(
          ((this.tasks[0].zag_width1 * this.tasks[0].zag_length1) / 1000000) *
            1000
        ) / 1000;
      const sq = sq_zag1 * this.result.count1;
      return sq == 0 ? "" : sq.toFixed(2);
    },

    countM2_2() {
      const sq_zag2 =
        Math.round(
          ((this.tasks[0].zag_width2 * this.tasks[0].zag_length2) / 1000000) *
            1000
        ) / 1000;
      const sq = sq_zag2 * this.result.count2;
      return sq == 0 ? "" : sq.toFixed(2);
    },

    countM2() {
      const sq_zag1 =
        Math.round(
          ((this.tasks[0].zag_width1 * this.tasks[0].zag_length1) / 1000000) *
            1000
        ) / 1000;
      const sq_zag2 =
        Math.round(
          ((this.tasks[0].zag_width2 * this.tasks[0].zag_length2) / 1000000) *
            1000
        ) / 1000;
      const sq = sq_zag1 * this.result.count1 + sq_zag2 * this.result.count2;
      return sq == 0 ? "" : sq.toFixed(2);
    },
  },

  mounted() {
    this.num = this.$route.params.num;
    this.loadTasks();
  },
};
</script>
  
<style scoped>
.table {
  font-size: smaller;
}

.selected_raport_row {
  background-color: lightgreen;
}

.table-bordered td {
  border: none !important;
  border-top: solid 1px darkgray !important;
  border-bottom: solid 1px darkgray !important;
  border-left: solid 1px darkgray !important;
  border-right: solid 1px darkgray !important;
}

.input_result {
  background-color: yellow;
}
.m2 {
  margin: 2px;
  border: 3px;
  border-style: solid;
  border-color: darkgray;
}

input {
  height: 25px;
}
</style>