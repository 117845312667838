<template>
  <div class="grid-wrapper">
    <div class="specs_grid specs_grid_columns">
      <div class="specs_grid_item specs_grid_item_title">Маршрут</div>
      <div class="specs_grid_item specs_grid_item_title">V</div>
      <div class="specs_grid_item specs_grid_item_title">Комм.</div>
      <div class="specs_grid_item specs_grid_item_title">Сумма</div>
      <div class="specs_grid_item specs_grid_item_title">Согл.</div>
      <div class="specs_grid_item specs_grid_item_title"></div>

      <template v-for="listitem in list" :key="listitem.num">
        <div class="specs_grid_item specs_grid_item_value">
          {{ listitem.mar }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ listitem.ob }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ listitem.komm }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ listitem.sum }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ listitem.sogl }}
          
        </div>

        <div class="specs_grid_item specs_grid_item_value">
          <button
            class="btn btn-success m2"
            @click="changeStatus(listitem.num)"            
          >
          <img src="../assets/swap24.png" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
  
<script>

export default {
  components: {},

  emits: ['changeStatusDelivery'],

  props: {
    list: {
      type: Array,
    },
  },

  methods: {
    changeStatus(num) {      
      this.$emit('changeStatusDelivery', num);
    }
  },
};
</script>

<style scoped>
.grid-wrapper {
  margin: 10px 10px 0 10px;
}

.specs_grid {
  margin: 0 auto;
  display: grid;
  font-size: small;
  max-width: 1000px;
  border-top: 1px solid;
  border-left: 1px solid;
}
.specs_grid_columns {
  grid-template-columns: repeat(6, auto);
}

.specs_grid_item {
  padding: 3px;
  border-color: gray;

  border-right: 1px solid;
  border-bottom: 1px solid;
}

.specs_grid_item_title {
  font-weight: bold;
}

.specs_grid_item_value {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  
  