<template>
  <nav-bar />

  <div>
    <div class="alert alert-primary d-flex align-items-center" role="alert" v-if="updateExists">
      <div style="margin: 10px"><strong>Внимание! </strong> Вышло обновление программы!</div>
      <button
        class="btn btn-success m2"
        style="margin: 10px"
        @click="refreshApp()"
      >
        Обновить
      </button>
    </div>
  </div>

  <div class="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import './assets/css/main.css';

export default {
  components: {
    NavBar,
  },

  data() {
    return {      
      refreshing: false,
      registration: null,
      updateExists: false,      
    };
  },

  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });

    //this.refreshAccess();
     setInterval(() => {
         this.refreshAccess();
        }, 60 * 1000); // 1 мин

  },

  methods: {
    
    isSafari() {
      return this.getBrowser().toUpperCase().includes('SAFARI');
    },

    getBrowser() {
      const { userAgent } = navigator;
      let match =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
      let temp;

      if (/trident/i.test(match[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

        return `IE ${temp[1] || ""}`;
      }

      if (match[1] === "Chrome") {
        temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

        if (temp !== null) {
          return temp.slice(1).join(" ").replace("OPR", "Opera");
        }

        temp = userAgent.match(/\b(Edg)\/(\d+)/);

        if (temp !== null) {
          return temp.slice(1).join(" ").replace("Edg", "Edge (Chromium)");
        }
      }

      match = match[2]
        ? [match[1], match[2]]
        : [navigator.appName, navigator.appVersion, "-?"];
      temp = userAgent.match(/version\/(\d+)/i);

      if (temp !== null) {
        match.splice(1, 1, temp[1]);
      }

      return match.join(" ");
    },

    updateAvailable(event) {      
      if (!this.isSafari()) {
        this.registration = event.detail;
        this.updateExists = true;
      } 
    },

    refreshAccess() {
      this.$store.dispatch('readAccess');
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
