<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center flex-row bd-highlight mb-3">
      <div class="p-2 bd-highlight">
        <input
          :value="dateToString(filter.date)"
          @input="filter.date = $event.target.valueAsDate"
          type="date"
          class="form-control"
          @change="loadTasks"
        />
      </div>

      <div class="p-2 bd-highlight">
        <select
          class="form-select"
          aria-label="Default select example"
          @change="loadTasks"
          v-model="filter.complete"
        >
          <option selected value="0">Невыполненные</option>
          <option value="1">Выполненные</option>
          <option value="2">Все</option>
        </select>
      </div>

      <div class="p-2 bd-highlight">
        <button
          class="btn btn-success m2"
          @click="
            $router.push(
              `/raportcutpage/${cutn}/${filter.date.getUTCFullYear()}/${filter.date.getUTCMonth()}/${filter.date.getUTCDate()}`
            )
          "
        >
          Отчет за смену
          <!-- <img src="../assets/book32.png" /> -->
        </button>
      </div>

    </div>
    <h4>Задания:</h4>
    <tasks-cut
      v-if="!loadingTasksInProgress && errorText == ''"
      :tasks="tasks"
      :cutn="cutn"
    ></tasks-cut>
    <div v-if="errorText == '' && loadingTasksInProgress">
      Загрузка заданий...
    </div>
    <div v-if="errorText != ''">{{ errorText }}</div>
  </div>
</template>
   
<script>
import TasksCut from "../components/TasksCut.vue";
import { fetchTasksCut } from "../api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

export default {
  components: {
    TasksCut,
  },

  data() {
    return {
      cutn: "",
      tasks: [],
      errorText: "",
      loadingTasksInProgress: false,
      filter: {
        date: new Date(),
        complete: 0,
      },
    };
  },

  watch: {
    // tasks(newTasks) {
    //   newTasks.forEach((task) => {
    //   });
    // },
  },

  methods: {
    async loadTasks() {
      this.loadingTasksInProgress = true;

      const fetchResult = await fetchTasksCut(
        this.formatDateToYYYYMMDD(this.filter.date),
        this.filter.complete,
        this.cutn
      );

      this.tasks = fetchResult.data;
      this.errorText = fetchResult.errorText;

      for (let i = 0; i < this.tasks.length; i++) {
        this.tasks[i].index = i + 1;
      }

      this.loadingTasksInProgress = false;
    },

    dateToString(date) {
      return date.toISOString().substring(0, 10);
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },

    formatDateToYYYYMMDD(date) {
      const year = date.toLocaleString("default", { year: "numeric" });
      const month = date.toLocaleString("default", { month: "2-digit" });
      const day = date.toLocaleString("default", { day: "2-digit" });
      return [year, month, day].join("");
    },
  },

  mounted() {
    const nowDate = new Date();

    this.filter.date = nowDate;
    this.cutn = this.$route.params.cutn;

    this.loadTasks();
  },
};
</script>
  