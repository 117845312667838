<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center flex-row bd-highlight mb-3">
      <div class="p-2 bd-highlight">
        <input
          :value="dateToString(filter.date)"
          @input="filter.date = $event.target.valueAsDate"
          type="date"
          class="form-control"
        />
      </div>

      <div class="p-2 bd-highlight">
        <label class="form-check-label" for="impBrigedeNum">
          <input
            v-model="filter.brigade_num"
            type="number"
            class="input_result form-control"
            id="impBrigedeNum"
          />
          № бригады
        </label>
      </div>

      <div class="p-2 bd-highlight">
        <button class="btn btn-success m2" @click="loadList">Показать</button>
      </div>

      <div class="p-2 bd-highlight">
        <button class="btn btn-dark m2" type="button" @click="$router.go(-1)">
          Вернуться назад
        </button>
      </div>
    </div>

    <raport-cut
      v-if="!loadingTasksInProgress && errorText == '' && tasks.length != 0"
      :tasks="tasks"
      :total_sum="total_sum"
      :total_count="total_count"
      :cutn="cutn"
    ></raport-cut>

    <raport-employees
      v-if="!loadingTasksInProgress && errorText == '' && tasks.length != 0"
      :employees="employees"
      :employees_total_sum="employees_total_sum"      
      :cutn="cutn"
    ></raport-employees>

    <div v-if="errorText == '' && loadingTasksInProgress">
      Загрузка заданий...
    </div>
    <h2 v-if="filter.brigade_num == '' || filter.brigade_num == 0">
      Укажите № бригады и нажмите "Показать"
    </h2>
    <div v-if="errorText != ''">{{ errorText }}</div>
  </div>
</template>
   
<script>
import RaportCut from "../components/RaportCut.vue";
import RaportEmployees from "../components/RaportEmployees.vue";
import { fetchRaportCut } from "../api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

export default {
  components: {
    RaportCut,
    RaportEmployees,
  },

  data() {
    return {
      cutn: "",
      tasks: [],
      total_count: "",
      total_sum: "",
      employees_total_sum: "",
      errorText: "",
      loadingTasksInProgress: false,
      filter: {
        date: new Date(),
        brigade_num: "",
      },
    };
  },

  watch: {
    // tasks(newTasks) {
    //   newTasks.forEach((task) => {
    //   });
    // },
  },

  methods: {
    async loadList() {
      this.loadingTasksInProgress = true;

      const fetchResult = await fetchRaportCut(
        this.formatDateToYYYYMMDD(this.filter.date),
        this.filter.brigade_num,
        this.cutn
      );

      const data = fetchResult.data;
      this.tasks = data.tasks;
      this.employees = data.employees;
      this.total_count = data.total_count;
      this.total_sum = data.total_sum;
      this.employees_total_sum = data.employees_total_sum;
      this.tasks = data.tasks;
      this.errorText = fetchResult.errorText;

      for (let i = 0; i < this.tasks.length; i++) {
        this.tasks[i].index = i + 1;
      }

      for (let i = 0; i < this.employees.length; i++) {
        this.employees[i].index = i + 1;
      }

      this.loadingTasksInProgress = false;
    },

    dateToString(date) {
      return date.toISOString().substring(0, 10);
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },

    formatDateToYYYYMMDD(date) {
      const year = date.toLocaleString("default", { year: "numeric" });
      const month = date.toLocaleString("default", { month: "2-digit" });
      const day = date.toLocaleString("default", { day: "2-digit" });
      return [year, month, day].join("");
    },
  },

  mounted() {
    const nowDate = new Date();

    this.filter.date = nowDate;
    if (this.cutn == "") this.cutn = this.$route.params.cutn;

    //this.loadList();
  },
};
</script>
  