<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center flex-row bd-highlight mb-3">
      <div class="p-2 bd-highlight">
        <label class="form-check-label" for="impNum">
          <input
            v-model="num"
            type="string"
            class="input_result form-control"
            id="impNum"
          />
          № техкарты
        </label>
      </div>

      <div class="p-2 bd-highlight">
        <button class="btn btn-success m2" @click="loadTehkarta">
          Техкарта
        </button>

        &nbsp;
        <button class="btn btn-success m2" @click="loadTranspkarta">
          Транспортная карта
        </button>
      </div>

      <div class="p-2 bd-highlight">
        <button class="btn btn-dark m2" type="button" @click="$router.go(-1)">
          Вернуться назад
        </button>
      </div>
    </div>

    <div v-if="loadingImageInProgress">Загрузка техкарты...</div>

    <div v-else style="margin: 10px">
      <img class="img-fluid" :src="'data:image/jpeg;base64,' + image" alt="" />
    </div>
  </div>
</template>

<script>
import { fetchImageTehKarta, fetchImageTranspKarta } from "../api.js";

export default {
  data() {
    return {
      loadingImageInProgress: false,
      image: "",
      num: "",
      errorText: "",
    };
  },

  methods: {
    async loadTehkarta() {
      this.loadingImageInProgress = true;
      const fetchResult = await fetchImageTehKarta(this.num);
      this.image = fetchResult.data;
      this.errorText = fetchResult.errorText;
      this.loadingImageInProgress = false;
    },

    async loadTranspkarta() {
      this.loadingImageInProgress = true;
      const fetchResult = await fetchImageTranspKarta(this.num);
      this.image = fetchResult.data;
      this.errorText = fetchResult.errorText;
      this.loadingImageInProgress = false;
    },
  },

  mounted() {},
};
</script>

<style>
</style>