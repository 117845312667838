<template>
  <div>
    <div v-if="loadingTasksInProgress">Загрузка задания...</div>
    <template v-else-if="errorText == ''">
      <div v-for="task in tasks" :key="task.taskkey">
        <div v-if="raports.length != 0">
          <h3>Ранее введенные рапортички:</h3>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td><strong>№</strong></td>
                <td><strong>Дата</strong></td>
                <td><strong>№ бригады</strong></td>
                <td><strong>Пропущено заготовок</strong></td>
                <td><strong>Выработано изделий, шт</strong></td>
                <td><strong>Брак, шт</strong></td>
                <td><strong>Некондиция, заг</strong></td>
                <td><strong>Расценка</strong></td>
                <td></td>
              </tr>
            </thead>

            <template v-for="raport in raports" :key="raport.raport_num">
              <tr
                :class="{
                  selected_raport_row: raport.raport_num == selected_raport_num,
                }"
              >
                <td>{{ raport.raport_num }}</td>
                <td>{{ dateToStringDDMMYYYY(raport.date) }}</td>
                <td>{{ raport.brigade_num }}</td>
                <td>{{ raport.count_zag }}</td>
                <td>{{ raport.count }}</td>
                <td>{{ raport.count_brak }}</td>
                <td>{{ raport.count_nekond }}</td>
                <td>{{ raport.price }}</td>

                <td>
                  <div class="task__btns">
                    <button
                      class="btn btn-success m2"
                      @click="startEditRaport(raport)"
                    >
                      Изменить
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </table>

          <br />
        </div>

        <h3>
          Рапортичка <strong>{{ selected_raport_num_str }}</strong> по заданию №
          {{ $route.params.tasknum }}
        </h3>

        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>Дата задания</td>
              <td>
                <strong> {{ dateToStringDDMMYYYY(task.date) }} </strong>
              </td>
              <td>№ бригады</td>
              <td>
                <input
                  v-model="result.brigade_num"
                  type="number"
                  class="input_result form-control"
                />
              </td>
            </tr>

            <tr>
              <td>Марка</td>
              <td>
                <strong>{{ task.marka }}</strong>
              </td>
              <td>Выработано изделий, шт</td>
              <td>
                <input
                  v-model="result.count"
                  type="number"
                  class="input_result form-control"
                />
              </td>
            </tr>

            <tr>
              <td>№ техкарты</td>
              <td>
                <div v-if="task.tehkarta" class="task__btns">
                  <strong>{{ task.tehkarta }}</strong>&nbsp;
                  <tehkarta-buttons :num="task.tehkarta"></tehkarta-buttons>                  
                </div>
              </td>
              <td>Брак, шт</td>
              <td>
                <input
                  v-model="result.count_brak"
                  type="number"
                  class="input_result form-control"
                />
              </td>
            </tr>

            <tr>
              <td>Наименование</td>
              <td>
                <strong>{{ task.name }}</strong>
              </td>
              <td>Некондиция, заг</td>
              <td>
                <input
                  v-model="result.count_nekond"
                  type="number"
                  class="input_result form-control"
                />
              </td>
            </tr>

            <tr>
              <td>Кол. изд. из 1 заг.</td>
              <td>
                <strong>{{ task.kol }}</strong>
              </td>
              <td>Расценка</td>
              <td>
                <input
                  v-model="result.price"
                  type="number"
                  class="input_result form-control"
                />
              </td>
            </tr>

            <tr>
              <td>Остаток заготовок</td>
              <td>
                <strong>{{ task.zag_ost }}</strong>
              </td>
              <td>Пропущено заготовок</td>
              <td>
                <strong>{{ count_zag }}</strong>
              </td>
            </tr>

            <tr>
              <td>Количество заготовок по заданию</td>
              <td>
                <strong>{{ task.task_count_zag }}</strong>
              </td>
              <td colspan="2"></td>              
            </tr>

            <tr>
              <td colspan="4">
                <div class="form-check form-switch" style="margin-left: 30px">
                  <label class="form-check-label" for="cbTaskComplete">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="task.complete"
                      id="cbTaskComplete"
                    />
                    Все задание выполнено
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          class="btn btn-success m2"
          :disabled="
            saveButtonDisabled || result.count == 0 || result.brigade_num == 0
          "
          @click="
            saveRaport(
              cutn,
              taskkey,
              tasknum,
              task.complete,
              selected_raport_num,
              result
            )
          "
        >
          {{ btnSave_title }}
        </button>

        <button
          v-if="selected_raport_num != ''"
          class="btn btn-success m2"
          @click="clearRaport"
        >
          Создать новую рапортичку
        </button>

        <button class="btn btn-dark m2" type="button" @click="$router.go(-1)">
          Вернуться назад
        </button>
      </div>
    </template>
    <div v-if="errorText != ''">{{ errorText }}</div>
  </div>
</template>
   
<script>
import TehkartaButtons from "../components/TehkartaButtons.vue";
import { fetchTaskDocCut } from "../api.js";
import { saveTaskDocCut } from "../api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

export default {
  components: { TehkartaButtons, },

  data() {
    return {
      tasks: [],
      raports: [],

      cutn: "",
      tasknum: "",
      taskkey: "",

      errorText: "",
      loadingTasksInProgress: false,
      selected_raport_num: "",
      saveButtonDisabled: false,
      result: {
        task_complete: false,
        brigade_num: "",
        kol: 0,
        count: "",
        count_nekond: "",
        count_brak: "",
        price: "",
      },
    };
  },

  watch: {
    tasks(newTasks) {
      newTasks.forEach((task) => {});
    },
  },

  methods: {
    async loadTasks() {
      this.loadingTasksInProgress = true;
      const fetchResult = await fetchTaskDocCut(
        this.cutn,
        this.taskkey,
        this.tasknum
      );

      this.tasks = fetchResult.data.tasks;
      this.raports = fetchResult.data.raports;
      this.errorText = fetchResult.errorText;

      this.loadingTasksInProgress = false;
    },

    async saveRaport(
      cutn,
      taskkey,
      tasknum,
      task_complete,
      selected_raport_num,
      data
    ) {
      this.saveButtonDisabled = true;
      console.log(this.tasks[0].kol);
      this.result.kol = this.tasks[0].kol;
      const fetchResult = await saveTaskDocCut(
        cutn,
        taskkey,
        tasknum,
        task_complete,
        selected_raport_num,
        data
      );
      if (fetchResult.bool_result == true) {
        this.selected_raport_num = fetchResult.selected_raport_num;
        this.loadTasks().then((this.saveButtonDisabled = false));
      } else {
        alert("Ошибка: " + fetchResult.errorText);
        this.saveButtonDisabled = false;
      }
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },

    clearRaport() {
      this.selected_raport_num = "";

      this.result.brigade_num = "";
      this.result.count = "";
      this.result.count_nekond = "";      
      this.result.count_brak = "";
      this.result.price = "";
    },

    startEditRaport(raport) {
      this.selected_raport_num = raport.raport_num;
      this.result.brigade_num = raport.brigade_num;
      this.result.count = raport.count;
      this.result.count_nekond = raport.count_nekond;
      this.result.count_brak = raport.count_brak;
      this.result.price = raport.price;
    },
  },

  computed: {
    btnSave_title() {
      return this.selected_raport_num == ""
        ? "Записать новую рапортичку"
        : "Перезаписать рапортичку № " + this.selected_raport_num;
    },

    selected_raport_num_str() {
      return this.selected_raport_num == ""
        ? "(новая рапортичка)"
        : "№ " + this.selected_raport_num;
    },

    count_zag() {
      return Math.ceil(
        (this.result.count + this.result.count_brak) /
          (this.tasks[0].kol == 0 ? 1 : this.tasks[0].kol)
      );
    },
  },

  mounted() {
    this.cutn = this.$route.params.cutn;
    this.taskkey = this.$route.params.taskkey;
    this.tasknum = this.$route.params.tasknum;

    this.loadTasks();
  },
};
</script>
  
<style scoped>
.table {
  font-size: smaller;
}

.selected_raport_row {
  background-color: lightgreen;
}

.table-bordered td {
  border: none !important;
  border-top: solid 1px darkgray !important;
  border-bottom: solid 1px darkgray !important;
  border-left: solid 1px darkgray !important;
  border-right: solid 1px darkgray !important;
}

.input_result {
  background-color: yellow;
}

.m2 {
  margin: 2px;
  border: 3px;
  border-style: solid;
  border-color: darkgray;
}

input {
  height: 25px;
}
</style>