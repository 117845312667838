<template>
  <div style="margin: 10px">
    <button class="btn btn-dark m2" type="button" @click="$router.go(-1)">
      Вернуться назад
    </button>
  </div>

  <div v-if="loadingImageInProgress">Загрузка техкарты...</div>

  <div v-else style="margin: 10px">
    <img class="img-fluid" :src="'data:image/jpeg;base64,' + image" alt="" />
  </div>
</template>

<script>
import { fetchImageTehKarta } from "../api.js";

export default {
  data() {
    return {
      loadingImageInProgress: false,
      image: "",
      num: null,
      errorText: "",
    };
  },

  methods: {
    async loadImage() {
      this.loadingImageInProgress = true;
      const fetchResult = await fetchImageTehKarta(this.num);
      this.image = fetchResult.data;
      this.errorText = fetchResult.errorText;
      this.loadingImageInProgress = false;
    },
  },

  mounted() {
    this.num = this.$route.params.num;
    this.loadImage();
  },
};
</script>

<style>
</style>