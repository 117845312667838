<template>
  <div v-if="tasks.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <td><strong>№</strong></td>
          <td><strong>Дата, марка</strong></td>
          <td colspan="4"><strong>Сырье</strong></td>
          <td colspan="3"><strong>1</strong></td>
          <td colspan="3"><strong>2</strong></td>
          <td><strong>Обрезь, Общ. М2.</strong></td>
          <td></td>
        </tr>
      </thead>

      <template v-for="task in tasks" :key="task.num">
        <tr
          :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.raports_count > 0,
          }"
        >
          <td rowspan="6">{{ task.index }}</td>
          <td rowspan="3">
            {{ dateToStringDDMMYYYY(task.date) }}
          </td>
          <td rowspan="2">Производитель</td>
          <td rowspan="2" colspan="2">Пл., формат</td>
          <td rowspan="2">Вес, кг</td>
          <td colspan="2">{{ task.zayavka_num1_str }}</td>
          <td>{{ task.todate1_str }}</td>
          <td colspan="2">{{ task.zayavka_num2_str }}</td>
          <td>{{ task.todate2_str }}</td>
          <td rowspan="3">{{ task.trim }} мм</td>
          <td rowspan="7">
            <div class="task__btns">
              <button
                class="btn btn-success m2"
                @click="$router.push(`/taskdocglinepage/${task.num}`)"
              >
                <img src="../assets/book32.png" />
              </button>
            </div>
          </td>
        </tr>

        <tr
          :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.raports_count > 0,
          }"
        >
          <td colspan="3">
            <strong>{{ task.name1_str }}</strong>
          </td>
          <td colspan="3">
            <strong>{{ task.name2_str }}</strong>
          </td>
        </tr>

        <tr
          :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.raports_count > 0,
          }"
        >
          <td align="left">{{ task.mat1_name }}</td>
          <td colspan="2">{{ task.mat1_density }}; {{ task.mat1_format }}</td>
          <td>{{ task.mat1_kg }}</td>
          <td colspan="2"></td>
          <td>{{ task.zag_sq1 }}</td>
          <td colspan="2"></td>
          <td>{{ task.zag_sq2 }}</td>
        </tr>

        <tr
          :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.raports_count > 0,
          }"
        >
          <td rowspan="3">
            {{ task.marka }}
          </td>
          <td align="left">{{ task.mat2_name }}</td>
          <td colspan="2">{{ task.mat2_density }}; {{ task.mat2_format }}</td>
          <td>{{ task.mat2_kg }}</td>
          <td>
            <strong>{{ task.zag_width1_str }}</strong>
          </td>
          <td>
            <strong>{{ task.zag_length1_str }}</strong>
          </td>
          <td rowspan="2">{{ task.strip_count1_str }}</td>
          <td>
            <strong>{{ task.zag_width2_str }}</strong>
          </td>
          <td>
            <strong>{{ task.zag_length2_str }}</strong>
          </td>
          <td rowspan="2">{{ task.strip_count2_str }}</td>
          <td rowspan="3">{{ task.count_sq }} м2</td>
        </tr>

        <tr
          :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.raports_count > 0,
          }"
        >
          <td align="left">{{ task.mat3_name }}</td>
          <td colspan="2">{{ task.mat3_density }}; {{ task.mat3_format }}</td>
          <td>{{ task.mat3_kg }}</td>
          <td colspan="2"></td>
          <td colspan="2"></td>
        </tr>

        <tr
          :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.raports_count > 0,
          }"
        >
          <td colspan="4"></td>
          <td>
            <strong>{{ task.count1_str }}</strong>
          </td>
          <td>{{ task.count_m2_1_str }}</td>
          <td><strong>контроль</strong></td>
          <td>
            <strong>{{ task.count2_str }}</strong>
          </td>
          <td>{{ task.count_m2_2_str }}</td>
          <td><strong>спутник</strong></td>
        </tr>

        <tr
          :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.raports_count > 0,
          }"
        >
          <td colspan="15" align="left">
            <strong>Примечание: {{ task.comment }}</strong>
          </td>
        </tr>
        <br />
      </template>
    </table>
  </div>
  <h2 v-else>Заданий нет</h2>
</template>

<script>
export default {
  components: {},

  props: {
    tasks: {
      type: Array,
    },
  },

  methods: {
    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },
  },
};
</script>

<style scoped>
.table {
  font-size: smaller;
}

.table thead {
  background-color: beige;
}

.table-bordered td {
  border: none !important;
  border-top: solid 1px darkgray !important;
  border-bottom: solid 1px darkgray !important;
  border-left: solid 1px darkgray !important;
  border-right: solid 1px darkgray !important;
}

.m2 {
  margin: 2px;
  border: 3px;
  border-style: solid;
  border-color: darkgray;
}

.tr_complete {
  background-color: lightgreen;
}

.tr_exists_raport {
  background-color: yellow;
}
</style>

