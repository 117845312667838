<template>    
    <button class="btn btn-success m2" @click="$router.push(`/tehkartaviewpage/${num}`)">
        <img src="../assets/search24.png" />
    </button>
    &nbsp;
    <button class="btn btn-success m2" @click="$router.push(`/transpkartaviewpage/${num}`)">
        <img src="../assets/car24.png" />
    </button>
</template>

<script>
    export default {
        name: 'tehkarta-buttons',

        props: {
             num: "",
        },        
    };
</script>

<style scoped>

</style>