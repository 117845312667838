<template>
  <div class="grid-wrapper">
    <div class="specs_grid specs_grid_columns">
      <div class="specs_grid_item specs_grid_item_title">Статус</div>
      <div class="specs_grid_item specs_grid_item_title">№ т.к.</div>
      <div class="specs_grid_item specs_grid_item_title">Наим.</div>
      <div class="specs_grid_item specs_grid_item_title">Цена в спец.</div>
      <div class="specs_grid_item specs_grid_item_title">Цена в прайсе</div>      
      <div class="specs_grid_item specs_grid_item_title">Дата</div>

      <template v-for="item in list" :key="item.index">
        <div class="specs_grid_item specs_grid_item_value">
          {{ item.status }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ item.tehkarta }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ item.name }}
        </div>
        <div class="specs_grid_item specs_grid_item_value" v-bind:class = "{bg_color_red: item.price_doc != item.price_pricelist }">
          {{ item.price_doc }}
        </div>
        <div class="specs_grid_item specs_grid_item_value" v-bind:class = "{bg_color_red: item.price_doc != item.price_pricelist }">
          {{ item.price_pricelist }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ dateToStringDDMMYYYY(item.price_pricelist_date) }}
        </div>
      </template>
    </div>
  </div>
</template>
  
<script>
export default {
  components: {},

  props: {
    list: {
      type: Array,
    },
    
  },

  methods: {
    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },
  },
};
</script>

<style scoped>
.grid-wrapper {
  margin: 10px 10px 0 10px;
}

.specs_grid {
  margin: 0 auto;
  display: grid;
  font-size: small;
  max-width: 1000px;
  border-top: 1px solid;
  border-left: 1px solid;
}
.specs_grid_columns {
  grid-template-columns: repeat(6, auto);
}

.specs_grid_item {
  padding: 3px;
  border-color: gray;

  border-right: 1px solid;
  border-bottom: 1px solid;
}

.specs_grid_item_title {
  font-weight: bold;
}

.specs_grid_item_value {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg_color_red {
  background-color: red;
}

</style>
  
  