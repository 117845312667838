<template>
  <div v-if="tasks.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <td><strong>№</strong></td>
          <td><strong>Дата</strong></td>
          <td><strong>Марка</strong></td>
          <td><strong>№ техкарты</strong></td>
          <td><strong>Наименование</strong></td>
          <td><strong>Кол. изд. на заготовке</strong></td>          
          <td><strong>Заготовок (план)</strong></td>
          <td><strong>Пропущено заготовок (факт)</strong></td>
          <td><strong>Примечание</strong></td>          
          <td></td>
        </tr>
      </thead>

      <template v-for="task in tasks" :key="task.taskkey">
        <tr :class="{
            tr_complete: task.complete,
            tr_exists_raport: task.count_complete > 0 && !task.complete,
          }">
          <td> {{ task.index }} </td>
          <td> {{ dateToStringDDMMYYYY(task.date) }} </td>          
          <td> {{ task.marka }} </td>
          <td> {{ task.tehkarta }} </td>          
          <td> {{ task.name }} </td>
          <td> {{ task.kol }} </td>          
          <td> {{ task.count }} </td>    
          <td><strong> {{ task.count_complete }} </strong></td>    
          <td> {{ task.comment }} </td>    
          <td>
            <div class="task__btns">
              <button
                class="btn btn-success m2"
                @click="$router.push(`/taskdoccutpage/${cutn}/${task.taskkey}/${task.tasknum}`)"
              >
                <img src="../assets/book32.png" />
              </button>
            </div>
          </td>
        </tr>        
      </template>
    </table>
  </div>
  <h2 v-else>Заданий нет</h2>
</template>

<script>
export default {
  components: {},

  props: {
    cutn: 0,
    tasks: {
      type: Array,
    },
  },

  methods: {
    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },
  },
};
</script>

<style scoped>
.table {
  font-size: smaller;
}

.table thead {
  background-color: beige;
}

.table-bordered td {
  border: none !important;
  border-top: solid 1px darkgray !important;
  border-bottom: solid 1px darkgray !important;
  border-left: solid 1px darkgray !important;
  border-right: solid 1px darkgray !important;
}

.m2 {
  margin: 2px;
  border: 3px;
  border-style: solid;
  border-color: darkgray;
}

.tr_complete {
  background-color: lightgreen;
}

.tr_exists_raport {
  background-color: yellow;
}
</style>

