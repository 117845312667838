<template>
  <div class="grid-wrapper">
    <div class="specs_grid" v-bind:class = "(buttonOpenVisible)?'specs_grid_columns_big':'specs_grid_columns_small'">
      <div class="specs_grid_item specs_grid_item_title">№</div>      
      <div class="specs_grid_item specs_grid_item_title">Префикс</div>
      <div class="specs_grid_item specs_grid_item_title">
        Дата вст.<br />в силу
      </div>            
      <div class="specs_grid_item specs_grid_item_title">Статус</div>      
      <div v-if="buttonOpenVisible" class="specs_grid_item specs_grid_item_title"></div>

      <template v-for="spec in specs" :key="spec.spec_id">
        <div class="specs_grid_item specs_grid_item_value">
          {{ spec.spec_num }}
        </div>        
        <div class="specs_grid_item specs_grid_item_value">
          {{ spec.spec_pref }}
        </div>
        <div class="specs_grid_item specs_grid_item_value">
          {{ dateToStringDDMMYYYY(spec.spec_datestart) }}
        </div>        
        <div class="specs_grid_item specs_grid_item_value">
          {{ spec.spec_status }}
        </div>        
        <div v-if="buttonOpenVisible" class="specs_grid_item specs_grid_item_value">
          <button
            class="btn btn-success m2"
            @click="$router.push(`/spec/${spec.spec_id}`)"
          >
            <img src="../assets/search24.png" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
  
<script>
export default {
  components: {},

  props: {
    specs: {
      type: Array,
    },
    buttonOpenVisible: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },
  },
};
</script>

<style scoped>
.grid-wrapper {
  margin: 10px 10px 0 10px;
}

.specs_grid {
  margin: 0 auto;
  display: grid;
  font-size: small;
  max-width: 1000px;
  border-top: 1px solid;
  border-left: 1px solid;
}
.specs_grid_columns_big {
  grid-template-columns: repeat(5, auto);
}
.specs_grid_columns_small {
  grid-template-columns: repeat(4, auto);
}

.specs_grid_item {
  padding: 3px;
  border-color: gray;

  border-right: 1px solid;
  border-bottom: 1px solid;
}

.specs_grid_item_title {
  font-weight: bold;
}

.specs_grid_item_value {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  
  