<template>
  <div class="div-title">Установка лимита задолженности</div>

  <div id="liveAlertPlaceholder"></div>

  <div>
    <input-autocomplete-customers
      id="customer_find"
      @selectItem="selectItem"
      @clearItem="clearItem"
    >
    </input-autocomplete-customers>
  </div>

  <div class="grid-wrapper">
    <div class="customer_grid" v-if="customer_selected">
      <div class="customer_grid_item customer_grid_item_title">
        Наименование
      </div>

      <div class="customer_grid_item customer_grid_item_value">
        <strong>{{ customer_name }}</strong>
      </div>

      <div class="customer_grid_item customer_grid_item_title">Код</div>

      <div class="customer_grid_item customer_grid_item_value">
        <strong>{{ customer_id }}</strong>
      </div>

      <div class="customer_grid_item customer_grid_item_title">
        Лимит задолженности:
      </div>
      <div class="customer_grid_item customer_grid_item_value">
        <input
          class="input_result form-control mar5"
          type="number"
          id="limit"
          v-model="limit"
        />
        <button
          class="btn btn-success m2 mar5"
          @click="setLimit"
          :disabled="saveButtonDisabled"
        >
          Установить лимит
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputAutocompleteCustomers from "../components/InputAutocompleteCustomers.vue";
import { setLimitToCustomer } from "../api";

export default {
  components: {
    InputAutocompleteCustomers,
  },

  data() {
    return {
      limit: 0,
      customer_id: "",
      customer_name: "",
      customer_selected: false,
      saveButtonDisabled: true,
    };
  },

  watch: {},

  methods: {
    selectItem(item) {
      this.customer_name = item.text;
      this.customer_id = item.id;
      this.limit = item.sum;
      this.customer_selected = true;
      this.saveButtonDisabled = false;
    },

    clearItem() {
      this.customer_name = "";
      this.customer_id = "";
      this.limit = 0;
      this.customer_selected = false;
      this.saveButtonDisabled = true;
    },

    async showAlert(message, type) {
      const alertPlaceholder = document.getElementById("liveAlertPlaceholder");

      const wrapper = document.createElement("div");
      wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible" role="alert">`,
        `   <div>${message}</div>`,
        '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
        "</div>",
      ].join("");

      alertPlaceholder.append(wrapper);
    },

    async setLimit() {
      this.saveButtonDisabled = true;
      const fetchResult = await setLimitToCustomer(
        this.customer_id,
        this.limit
      );
      if (fetchResult.bool_result == true) {
        await this.showAlert(
          `Лимит в размере "${this.limit}" установлен для "${this.customer_name}" !`,
          "success"
        );
      } else {
        alert("Ошибка: " + fetchResult.errorText);
      }
    },
  },
};
</script>

<style>
.grid-wrapper {
  margin: 10px 10px 0 10px;
}

.customer_grid {
  margin: 0 auto;
  display: grid;  
  grid-template-columns: 1fr 1fr;
  max-width: 1000px;
  border-top: 1px solid;
  border-left: 1px solid;
}

.customer_grid_item {
  padding: 10px;  
  border-color: gray;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.customer_grid_item_title {
  text-align: end;
}

.customer_grid_item_value {
  text-align: start;
}

.panel {
  border: 1px solid;
  padding: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.mar5 {
  margin: 5px 0px;
}
</style>