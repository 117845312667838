import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import components from './components/UI/'
import './registerServiceWorker'

const app = createApp(App);

components.forEach(component => {
    app.component(component.name, component)
})

store.dispatch('initStore');

app
.use(store)
.use(router)
.mount('#app')

