<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center flex-row bd-highlight mb-3">
      <div class="p-2 bd-highlight">
        <!-- <label class="form-label">Дата</label> -->
        <input
          :value="dateToString(filter.date)"
          @input="filter.date = $event.target.valueAsDate"
          type="date"
          class="form-control"
          @change="loadTasks"
        />
      </div>

      <div class="p-2 bd-highlight">
        <select
          class="form-select"
          aria-label="Default select example"
          @change="loadTasks"
          v-model="filter.complete"
        >
          <option selected value="0">Невыполненные</option>
          <option value="1">Выполненные</option>
          <option value="2">Все</option>
        </select>
      </div>
    </div>
    <tasks-gline
      v-if="!loadingTasksInProgress && errorText == ''"
      :tasks="tasks"
    ></tasks-gline>
    <div v-if="errorText == '' && loadingTasksInProgress">
      Загрузка заданий...
    </div>
    <div v-if="errorText != ''">{{ errorText }}</div>
  </div>
</template>
   
<script>
import TasksGline from "../components/TasksGline.vue";
import { fetchTasksGline } from "../api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

export default {
  components: {
    TasksGline,
  },

  data() {
    return {
      tasks: [],
      errorText: "",
      loadingTasksInProgress: false,
      filter: {
        date: new Date(),
        complete: 0,
      },
    };
  },

  watch: {
    tasks(newTasks) {
      newTasks.forEach((task) => {
        task.zayavka_num1_str = task.without_zayavka1 ? "Без заявки" : (task.zayavka_num1 == "" ? "" : "Заявка №" + task.zayavka_num1);
        task.zayavka_num2_str = task.without_zayavka2 ? "Без заявки" : (task.zayavka_num2 == "" ? "" : "Заявка №" + task.zayavka_num2);

        task.todate1_str = task.zayavka_num1 == "" ? "" : this.dateToStringDDMMYYYY(task.todate1);
        task.todate2_str = task.zayavka_num2 == "" ? "" : this.dateToStringDDMMYYYY(task.todate2);

        task.name1_str = task.tehkarta1 == "" ? "" : "тк. №" + task.tehkarta1 + " - " + task.name1;
        task.name2_str = task.tehkarta2 == "" ? "" : "тк. №" + task.tehkarta2 + " - " + task.name2;        
                
        task.zag_sq1 = task.zag_width1 == 0 ? "" : "S = " + (task.zag_width1 * task.zag_length1 / 1000000).toFixed(3);
        task.zag_sq2 = task.zag_width2 == 0 ? "" : "S = " + (task.zag_width2 * task.zag_length2 / 1000000).toFixed(3);
        
        task.zag_width1_str = task.zag_width1 == 0 ? "" : task.zag_width1;
        task.zag_width2_str = task.zag_width2 == 0 ? "" : task.zag_width2;

        task.zag_length1_str = task.zag_length1 == 0 ? "" : task.zag_length1;
        task.zag_length2_str = task.zag_length2 == 0 ? "" : task.zag_length2;
        
        task.strip_count1_str = task.strip_count1 == 0 ? "" : "Полос: " + task.strip_count1;
        task.strip_count2_str = task.strip_count2 == 0 ? "" : "Полос: " + task.strip_count2;
        
        task.count1_str = task.count1 == 0 ? "" : task.count1 + " заг";
        task.count2_str = task.count2 == 0 ? "" : task.count2 + " заг";

        task.count_m2_1_str = task.count_m2_1 == 0 ? "" : task.count_m2_1 + " м2";
        task.count_m2_2_str = task.count_m2_2 == 0 ? "" : task.count_m2_2 + " м2";
        
        
        
      });
    },
  },

  methods: {
    async loadTasks() {
      this.loadingTasksInProgress = true;

      const fetchResult = await fetchTasksGline(
        this.formatDateToYYYYMMDD(this.filter.date),
        this.filter.complete
      );

      this.tasks = fetchResult.data;
      this.errorText = fetchResult.errorText;

      for (let i = 0; i < this.tasks.length; i++) {
        this.tasks[i].index = i + 1;
        if (this.tasks[i].complete) {
          this.tasks[i].raports_count = 0;
        }
      }

      this.loadingTasksInProgress = false;
    },

    dateToString(date) {
      return date.toISOString().substring(0, 10);
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },

    formatDateToYYYYMMDD(date) {
      const year = date.toLocaleString("default", { year: "numeric" });
      const month = date.toLocaleString("default", { month: "2-digit" });
      const day = date.toLocaleString("default", { day: "2-digit" });
      return [year, month, day].join("");
    },
  },

  mounted() {
    const nowDate = new Date();

    this.filter.date = nowDate;

    this.loadTasks();
  },
};
</script>
  