<template>
    <vue3-simple-typeahead
      id="demo"
      placeholder="Контрагент"          
      @onInput="onInput"      
      :items="items"
      :minInputLength="1"
      :itemProjection="
        (item) => {
          return item.text;
        }
      "
    >
    </vue3-simple-typeahead>
  </template>
  
  <script>
  import Vue3SimpleTypeahead from "./typeahead/vue3-simple-typeahead.vue";
  import { fetchCustomersWithFilter } from "../api";

  export default {
    components: {
      Vue3SimpleTypeahead,
    },
  
    data() {
      return {
        items: [],        
      };
    },
  
    watch: {},
  
    methods: {
  
      onInput(event) {
        if (event.input.length > 2) {
          this.loadItems(event.input, 10);
        } else {
          this.items = [];
        }
      },
  
      async loadItems(filter, top) {
        const fetchResult = await fetchCustomersWithFilter(filter, top);
  
        this.items = fetchResult.data;
      },
    },
  };
  </script>
  
  <style>
  </style>