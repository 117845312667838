<template>  
  <div v-if="employees.length > 0">
    <table class="table table-bordered">
      <thead>
        <tr>
          <td><strong>№</strong></td>
          <td><strong>ФИО</strong></td>
          <td><strong>Разряд</strong></td>
          <td><strong>Часовой тариф, руб/ч</strong></td>
          <td><strong>Отработано часов</strong></td>
          <td><strong>Сумма</strong></td>
        </tr>
      </thead>

      <template v-for="employee in employees" :key="employee.index">
        <tr>
          <td> {{ employee.index }} </td>          
          <td> {{ employee.name }} </td>
          <td> {{ employee.class }} </td>
          <td> {{ employee.tarif }} </td>
          <td> {{ employee.hours }} </td>
          <td> {{ employee.sum }} </td>
        </tr> 
        
      </template>
      <tr>
        <td colspan="5" style="text-align: left"><strong>Итого:</strong></td>        
        <td><strong>{{employees_total_sum}}</strong></td>
      </tr>
    </table>
  </div>  
</template>

<script>
export default {
  components: {},

  props: {
    cutn: 0,    
    employees_total_sum: "",
    employees: {
      type: Array,
    },
  },

  methods: {
    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },
  },
};
</script>

<style scoped>
.table {
  font-size: smaller;
}

.table thead {
  background-color: beige;
}

.table-bordered td {
  border: none !important;
  border-top: solid 1px darkgray !important;
  border-bottom: solid 1px darkgray !important;
  border-left: solid 1px darkgray !important;
  border-right: solid 1px darkgray !important;
}

.m2 {
  margin: 2px;
  border: 3px;
  border-style: solid;
  border-color: darkgray;
}

.tr_complete {
  background-color: lightgreen;
}

.tr_exists_raport {
  background-color: yellow;
}
</style>

