<template>
  <div class="div-title">Согласование доставки</div>

  <div id="liveAlertPlaceholder"></div>

  <div v-if="loadingTasksInProgress">Загрузка списка...</div>

  <delivery-list
    @changeStatusDelivery="changeStatusDelivery"
    :list="list"
    v-if="!loadingTasksInProgress && list.length != 0"
  ></delivery-list>
</template>

<script>
import DeliveryList from "../components/DeliveryList.vue";
import { fetchDeliveryList } from "../api";
import { changeStatusDelivery } from "../api.js";

export default {
  components: {
    DeliveryList,
  },

  data() {
    return {
      list: [],
      customer_id: "",
      customer_name: "",
      customer_selected: false,
      loadingTasksInProgress: false,
    };
  },

  watch: {},

  methods: {
    async loadList() {
      this.loadingTasksInProgress = true;

      const fetchResult = await fetchDeliveryList();

      this.list = fetchResult.data;
      this.errorText = fetchResult.errorText;

      this.loadingTasksInProgress = false;
    },

    async showAlert(message, type) {
      const alertPlaceholder = document.getElementById("liveAlertPlaceholder");

      const wrapper = document.createElement("div");
      wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible" role="alert">`,
        `   <div>${message}</div>`,
        '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
        "</div>",
      ].join("");

      alertPlaceholder.append(wrapper);
    },

    async changeStatusDelivery(num) {
      await changeStatusDelivery(num);
      this.loadList();
    },

  },

  mounted() {
    this.loadList();    
  },
};
</script>

<style>
</style>