<template>
  <div>
    <div class="div-title" v-if="loadingTasksInProgress">Загрузка спецификации...</div>
    <div class="div-title" v-if="saveInProcess">Подождите... Согласование и перенос цен...</div>
    <template v-else-if="errorText == ''">

      <div id="liveAlertPlaceholder"></div>

      <div v-for="spec in doc" :key="spec.spec_id">
        <div class="div-title">Спецификация:</div>
        
        <specs-list :specs="doc" :buttonOpenVisible="false"></specs-list>

        <div class="div-title">Состав спецификации:</div>

        <spec-doc-list :list="list"></spec-doc-list>

        <button
          class="btn btn-success m2 mar5"
          @click="setPrice"
          :disabled="saveButtonDisabled"
        >
          Согласовать и перенести в цены
        </button>

      </div>
    </template>
    <div class="div-title" v-if="errorText != ''">{{ errorText }}</div>
  </div>
</template>
   
<script>
import { fetchSpec } from "../api.js";
import { setPriceBySpec } from "../api.js";
import SpecsList from "../components/SpecsList.vue";
import SpecDocList from "../components/SpecDocList.vue";


export default {
  components: {
    SpecsList,
    SpecDocList,
  },

  data() {
    return {
      doc: [],
      list: [],
      spec_id: "",
      errorText: "",
      saveInProcess: false,
      loadingTasksInProgress: false,
      saveButtonDisabled: true,
    };
  },

  watch: {},

  methods: {
    async loadSpec() {
      this.loadingTasksInProgress = true;

      const fetchResult = await fetchSpec(this.spec_id);

      this.doc = fetchResult.data.doc;
      this.list = fetchResult.data.list;
      this.errorText = fetchResult.errorText;

      this.loadingTasksInProgress = false;
      this.saveButtonDisabled = false;
    },

    async setPrice() {
      this.saveButtonDisabled = true;
      this.saveInProcess = true;
      const fetchResult = await setPriceBySpec(
        this.spec_id        
      );
      this.saveButtonDisabled = false;
      this.saveInProcess = false;
      if (fetchResult.bool_result == true) {
        await this.loadSpec();
        await this.showAlert(
          'Цены перенесены!',
          "success"
        );
      } else {
        alert("Ошибка: " + fetchResult.errorText);
      }
    },

    async showAlert(message, type) {
      const alertPlaceholder = document.getElementById("liveAlertPlaceholder");

      const wrapper = document.createElement("div");
      wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible" role="alert">`,
        `   <div>${message}</div>`,
        '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
        "</div>",
      ].join("");

      alertPlaceholder.append(wrapper);
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },
  },

  computed: {},

  mounted() {
    this.spec_id = this.$route.params.spec_id;
    this.loadSpec();
  },
};
</script>
  
<style scoped>


</style>