<template>
  <div class="div-title">Установка цен</div>

  <div id="liveAlertPlaceholder"></div>

  <div>
    <input-autocomplete-customers
      id="customer_find"
      @selectItem="selectItem"
      @clearItem="clearItem"
    >
    </input-autocomplete-customers>
  </div>

  <div class="div-title" v-if="!loadingTasksInProgress && specs.length != 0">Спецификации</div>

  <div v-if="loadingTasksInProgress">Загрузка спецификаций...</div>
  
  <specs-list :specs="specs" :buttonOpenVisible="true" v-if="!loadingTasksInProgress && specs.length != 0"></specs-list>
  
</template>

<script>
import InputAutocompleteCustomers from "../components/InputAutocompleteCustomers.vue";
import SpecsList from "../components/SpecsList.vue";
import { fetchSpecs } from "../api";


export default {
  components: {
    InputAutocompleteCustomers,
    SpecsList,
  },

  data() {
    return {
      specs: [],
      customer_id: "",
      customer_name: "",
      customer_selected: false,
      loadingTasksInProgress: false,
    };
  },

  watch: {},

  methods: {
    selectItem(item) {
      this.customer_name = item.text;
      this.customer_id = item.id;
      this.customer_selected = true;
      this.loadSpecs();
    },

    clearItem() {
      this.customer_name = "";
      this.customer_id = "";
      this.customer_selected = false;
      this.specs = [];
      this.loadingTasksInProgress = false;
    },

    async loadSpecs() {
      this.loadingTasksInProgress = true;

      const fetchResult = await fetchSpecs(this.customer_id, 10);

      this.specs = fetchResult.data;
      this.errorText = fetchResult.errorText;

      this.loadingTasksInProgress = false;
    },

    async showAlert(message, type) {
      const alertPlaceholder = document.getElementById("liveAlertPlaceholder");

      const wrapper = document.createElement("div");
      wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible" role="alert">`,
        `   <div>${message}</div>`,
        '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
        "</div>",
      ].join("");

      alertPlaceholder.append(wrapper);
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },
  },
};
</script>

<style>

</style>